import Schedule from "../components/Schedule";


const EventDetails = () => {
    return (
        <div className="schedule m-5 text-2xl">
        <Schedule />
        

        </div>
    );
}
export default EventDetails;